import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
// import Home from "../pages/Home.js";
import NavBar from "../pages/NavBar.js";
import Home from "../pages/Home.js";
import LearningRoadMap from "../pages/LearningRoadMap.js";
import SeniorProject from "../pages/Senior_project.js";
// import Porfolio from "../pages/Porfolio.js";
import Project from "../pages/Project.js";
import SocialMedia from "../pages/SocialMedia.jsx";


import ErrorPage from "../pages/ErrorPage.js";
// import "../css/navbar.css";

function Navbar() {
  // // 對所有連結添加target, _blank 屬性
  useEffect(() => {
    const target_blank = document.querySelectorAll("a");
    target_blank.forEach((link) => {
      link.setAttribute("target", "_blank");
      // link.setAttribute("rel", "noopener noreferrer");
    });
  }, []);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        target="_blank"
        rel="noopener noreferrer"
        element={<NavBar />}
      >
        <Route path="/" element={<Home />} />,
        <Route path="/LearningRoadMap" element={<LearningRoadMap />} />,
        <Route path="/seniorProject" element={<SeniorProject />} />,
        {/* <Route path="/porfolio" element={<Porfolio />} /> */}
        <Route path="/project" element={<Project />} />
        <Route path="/social" element={<SocialMedia />} />

        <Route path="*" element={<ErrorPage />} />
      </Route>
    )
  );

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Navbar;
