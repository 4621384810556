import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import "../css/page.css";
import SHT31 from "../Document/SHT31.png";
import ACS712 from "../Document/ACS712.png";
import FSR406 from "../Document/FSR406.png";
import Fsr406_4x5_1 from "../Document/FSR406_4x5_picture1.jpg";
import Fsr406_4x5_2 from "../Document/FSR406_4x5_picture2.jpg";
import Fsr406_4x5_3 from "../Document/FSR406_4x5_picture3.jpg";
import pdf from "../Document/20221221_專題研究報告書_整合多重感測器裝置與互動功能獨居高齡者預警關懷系統開發.pdf";

function Senior_project() {
  return (
    <>
      <div className="content">
        <h1>專題名稱：合多重感測器裝置與互動功能獨居高齡者預警-關懷系統開發</h1>
        <h3>感測器：</h3>
        {/* <Link to="">SHT31</Link>(溫溼度感測器)/<Link to="">ACS712</Link>
        (電流感測器)/
        <Link to="">FSR406</Link>(壓力感測器) */}
        <div className="sensor-image">
          <img src={SHT31} alt="SHT31"></img>
          <h3>
            <Link
              to="https://sensirion.com/media/documents/213E6A3B/63A5A569/Datasheet_SHT3x_DIS.pdf"
              target="_blank"
            >
              SHT31(溫溼度感測器)
            </Link>
          </h3>
          <img src={ACS712} alt="ACS712"></img>
          <h3>
            <Link
              to="https://pdf1.alldatasheet.es/datasheet-pdf/view/174115/ALLEGRO/ACS712_06.html"
              target="_blank"
            >
              ACS712(電流感測器)
            </Link>
          </h3>
          <img src={FSR406} alt="FSR406"></img>
          <h3>
            <Link
              to="https://cdn.sparkfun.com/assets/c/4/6/8/b/2010-10-26-DataSheet-FSR406-Layout2.pdf"
              target="_blank"
            >
              FSR406(壓力感測器)
            </Link>
          </h3>
        </div>
        <h3>
          專題用到的技能：HTML/CSS/Javascript/PHP/jQuery/MySQL/Java(Android
          Studio Layout, XML)
        </h3>
        <h3>
          負責領域：App端的頁面布局、資料庫的創建與呈現、登入系統、API串接(
          <Link to="https://www.twilio.com/docs/sms" target="_blank">
            Twilio-SMS
          </Link>
          )
        </h3>
        <h3>
          摘要：針對獨居高齡者照顧的角度進行探討，其主要的目標在於不干擾高齡者的作息且維護高齡者隱私的情況下，了解高齡者的生活狀況並適時給予協助，進而提升其生活品質及減少照顧人力負擔。
        </h3>
        <div className="wrap-image">
          <img src={Fsr406_4x5_1} alt="Fsr406_4x5_1"></img>
          <img src={Fsr406_4x5_2} alt="Fsr406_4x5_2"></img>
          <img src={Fsr406_4x5_3} alt="Fsr406_4x5_3"></img>
        </div>
        <ReactPlayer
          url="https://www.youtube.com/watch?v=Nfbi493TOnM&ab_channel=%E9%96%83%E9%9B%BBo%E5%B7%A8%E9%BE%8D"
          controls={true}
        />
        <h3>
          More Details：
          <Link
            to="https://takagisanmaaa.000webhostapp.com/index_success.php"
            target="_blank"
          >
            專題網站連結
          </Link>
          &emsp;
          <Link
            to="https://www.youtube.com/watch?v=Nfbi493TOnM&ab_channel=%E9%96%83%E9%9B%BBo%E5%B7%A8%E9%BE%8D"
            target="_blank"
          >
            專題展示影片
          </Link>
          &emsp;
          <Link to={pdf} target="_blank">
            專題PDF Download
          </Link>
        </h3>
      </div>
    </>
  );
}

export default Senior_project;
