import React from "react";
import { Link } from "react-router-dom";
import "../css/roadMap.css";

export default function LearningRoadMap() {
  return (
    <>
      <body>
        <div class="limiter">
          <div class="container-table100">
            <div class="wrap-table100">
              <div class="table">
                <div class="row header">
                  <div class="cell">內容</div>
                  <div class="cell">日期</div>
                  <div class="cell">描述</div>
                  <div class="cell">進度</div>
                </div>
                <div class="row">
                  <div class="cell">Android Studio WebView</div>
                  <div class="cell">2022/06/09</div>
                  <div class="cell">
                    原本預計用Java來撰寫前端的應用程式,
                    後來改成用webview的方式呈現
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">HTML Layout SELECT</div>
                  <div class="cell">2022/9/10</div>
                  <div class="cell">
                    將HTML option結果串接成SQL Command, 從前端向資料庫請求資料
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">FSR406_Page</div>
                  <div class="cell">2022/9/20</div>
                  <div class="cell">
                    根據資料庫資料, 初版彩色的壓力感測介面-[白, 綠, 黃, 橘, 紅]
                    (2x3)
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">單一時間線的資料查詢</div>
                  <div class="cell">2022/9/25</div>
                  <div class="cell">
                    將資料庫中溫度/濕度/電流的資料呈現到APP上
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">專題瓶頸-臨時動議</div>
                  <div class="cell">2022/9/28</div>
                  <div class="cell">
                    隨著文書相關資料逐漸完整, 感測器與應用層面重新制定計畫,
                    為了與專題主旨契合,
                    也將壓力感測器的用途轉變為偵測獨居高臨者的睡眠品質,
                    也將感測器從2x3擴充到4x5,
                    並否決掉原本異色非直觀的壓力顯示判斷機制, 統一改成單一色系,
                    增加更加鮮明的對比(紅/灰)
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">年月日的獨立下拉選單</div>
                  <div class="cell">2022/10/1</div>
                  <div class="cell">
                    結合ajax(Remove/Append), 重新渲染的方式,
                    用戶每選擇完任何一個(年/月/日)就會去訪問該時間的資料,
                    而非一次性顯示出來(EX:選了2022，他就會以2022作為參數~now(),
                    重新渲染資料庫確實存在的option, 在上述情況,
                    才會重新去讀取到資料庫2022年至今有的資料),確保選擇到的是特定時間區間,
                    而不會有無資料的情況發生, (因為不見得每天都會有資料,
                    也不能引用html內建的時間格式/月曆樣板,
                    因為可能會請求到沒有資料的日期區段),
                    所以就著手自製我的select, option, 也增加時間區段的功能,
                    並把確認按鈕給移除。
                    <br />
                    最終的功能：
                    當使用者選完(年/月/日~年/月/日)會自動請求該時間區段的資料。
                    重新選擇任何一個時間段時, P.S.則可以清除資料並重新顯示,
                    只需重新選擇(任一 年/月/日)。(每個下拉選單都有獨自的SQL請求,
                    確保資料確實存在)
                    <br />
                    這也是這個專題遇到最大的瓶頸。
                  </div>
                  <div class="cell">Delay_Finished</div>
                </div>
                <div class="row">
                  <div class="cell">
                    專題展迫在眉睫, 壓力逐漸變大, 功能卻還尚未完善
                  </div>
                  <div class="cell">2022/11/19</div>
                  <div class="cell">
                    請另一位同學設計loginPage, 減少研究css的時間,
                    當時時間比較緊迫, 也預期要加上新的功能臨時決議的,
                    匆匆忙忙之下我也開始有點對於進度慌張,
                    還好前面已經對頁面跳轉/function十分熟悉,
                    所以這邊進展算是快的
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">加上即時資料呈現的功能</div>
                  <div class="cell">2022/11/21</div>
                  <div class="cell">
                    尚未選擇時間前(選到FSR406的圖片時,
                    會自動顯示最新的資料於頁面上)
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">開始部屬程式到Linux Server上</div>
                  <div class="cell">2022/11/28</div>
                  <div class="cell">
                    安裝需要的程式Composer/Apache Server/Adminer
                  </div>
                  <div class="cell">Fail</div>
                </div>
                <div class="row">
                  <div class="cell">確認先前功能無誤(存檔點)</div>
                  <div class="cell">2022/12/7</div>
                  <div class="cell">
                    確認更改後的新功能無誤, 和資料庫回傳的資料,
                    並儲存成一個版本, 著手處裡想要渲染到Dom的圖片,
                    回傳的資料格式再判斷是否壓的數值是否大於0,
                    如果大於0就要呈現[red.png]; 否則顯示[gray.png]
                    每張(4x5)圖片判斷20次 x N筆資料，並用ajax跑回圈渲染出UI
                  </div>
                  <div class="cell"></div>
                </div>
                <div class="row">
                  <div class="cell">Login System</div>
                  <div class="cell">2022/11/26</div>
                  <div class="cell">
                    透過PHP來實作登入系統(signup/login/logout/forget_password/recovery_password/Authentication/reset_password),
                    並將forget_password串接到Twilio-SMS(API)發送簡訊,
                    並加以認證其身份, 給予權限訪問重設密碼的頁面,
                    確保兩次輸入相同且無誤, 再將使用者導引回loginPage
                  </div>
                  <div class="cell">Finished</div>
                </div>
                <div class="row">
                  <div class="cell">Twilio_API-SMS</div>
                  <div class="cell">2022/12/4</div>
                  <div class="cell">
                    透過PHP來實現寄送簡訊的服務, 用途：Forgot_Password Page,
                    創建亂數產生器6碼[A-Z, 0-9], 這邊當時有發生個小意外,
                    驅動Twilio的Dependencies Composer在Raspberry
                    Pi上出了些小問題, 無法正常啟動, 最後感謝另一位夥伴,
                    有改寫Server端的程式(用CLI來驅動Twilio的功能)
                    開發階段在Windows上測都沒啥問題的說...
                  </div>
                  <div class="cell">Finished</div>
                </div>

                <div class="row">
                  <div class="cell"></div>
                  <div class="cell"></div>
                  <div class="cell"></div>
                  <div class="cell"></div>
                </div>
                <div class="row">
                  <div class="cell"></div>
                  <div class="cell"></div>
                  <div class="cell"></div>
                  <div class="cell"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>
          Styles Reference：
          <Link
            to="https://colorlib.com/etc/tb/Table_Responsive_v2/index.html"
            target="_blank"
          >
            Source Code
          </Link>
        </h3>
      </body>
    </>
  );
}
