import React from "react";
// import ReactPlayer from 'react-player'
import me from "../Document/me.jpg";
import { Link } from "react-router-dom";
import "../css/page.css";

function Home() {
  console.log(`視窗寬度：${window.innerWidth}`);
  console.log(`視窗高度：${window.innerHeight}`);

  return (
    <>
      {/* <h3>Home頁面</h3> */}
      <div
        className="content"
        style={{ backgroundColor: "rgb(229,240,254)", padding: "10px" }}
      >
        <div className="home">
          <img
            src={me}
            style={{
              height: "240px",
              width: "240px",
            }}
            alt="me"
          ></img>

          <div className="text">
            <h3>姓名：潘彥瑜 (JamesPan)</h3>
            <h3>學歷：亞東科技大學-電子工程系</h3>
            {/* <h3>
              技能：HTML/CSS/Javascript/PHP/jQuery/React/Express/MySQL/Webpack
            </h3> */}
            <h3>興趣：騎腳踏/寫程式/研究加密貨幣</h3>
            <h3>
              期望工作：前端/後端/全端-工程師
              <Link to="https://pda.104.com.tw/profile/share/eTGieJFFoEdiLLpSGJTuuB7kl4TOVWWz">
                (104簡歷)
              </Link>
            </h3>
            <h3>
              Skill：React、Express、NodeJS(Javascript)、MySQL、PHP、WebPack、Git
            </h3>
            <h3>
              信箱：
              <Link to="mailto:takagisanmaaa@gmail.com">
                takagisanmaaa@gmail.com
              </Link>
              (點擊跳轉寄信頁面)
            </h3>
            <h3>
              實習單位：香港商聯寶電腦有限公司台灣分公司-軟體測試工程師(2022/07-2023-06)
            </h3>
            <h3>
              實習內容：測試BIOS/EC/ME/ChromeBook的Firmware，多種升降版功能是否正常(UEFI-Shell、CD-ISO、WinFlash、SPI、Self-Healing...etc)
            </h3>
            {/* <h3>
              簡歷：專題負責前端資料的呈現、創建資料庫。(校內專題競賽第三名)
            </h3>
            <h3>
              專題用到的技能：HTML+CSS+Javascript、PHP、jQuery、000webhost。
            </h3>
            <h3>
              畢業後待業等待當兵的時間，自學並進修加強對後端的認知，了解MVC的架構、前後端分離的重要性、模組化程式，並重新撰寫登入系統
            </h3>

            <h3>
              API撰寫經驗：ChatGPT、Twilio、Pexels、Openweathermap,
              使用過codemirror優化程式碼在前端頁面中的呈現等。
            </h3>
            <h3>
              前陣子在撰寫LinePay的API(嘗試串接金流)，從0開始，模擬飲料店的網頁支付流程、產生訂單，其中也有遇到些許身分驗證的困難，花了不少時間去學習CryptoJS的API，並了解Token-Based
              Authentication & SHA256+Base64的方式等等......。
            </h3>
            <h3>
              畢業後也過了幾個月，因此也逐漸開始把重心放在找工作上面，於是就想來做個個人網站凸顯自己的能力，希望被更多人看到
            </h3> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
