import React from "react";
import { Link } from "react-router-dom";

import Facebook from "../Document/Facebook.png";
import Discord from "../Document/Discord.png";
import Twitter from "../Document/Twitter.png";
import Telegram from "../Document/Telegram.png";
import Wechat from "../Document/Wechat.png";
import Instagram from "../Document/Instagram.png";
import Github from "../Document/Github.png";

import "../css/socialMedia.css";

function Logo(props) {
  const { logo } = props;

  const logoMappiing = {
    Facebook,
    Discord,
    Twitter,
    Telegram,
    Wechat,
    Github,
    Instagram,
  };
  console.log(`test：${logoMappiing.Discord}`);

  const logoToDisplay = logoMappiing[logo];

  console.log(`社交媒体：${logo}, 图像路径：${logoToDisplay}`);

  if (!logoToDisplay) {
    console.log(`找不到图像：${logo}`);
    return null;
  }

  const url_link = {
    Facebook: "https://www.facebook.com/cryptopan2022",
    Discord: "https://discordapp.com/users/james1pan",
    Twitter: "https://twitter.com/james347750",
    Telegram: "https://t.me/cryptopan2022",
    Wechat: "https://u.wechat.com/kMqhK3MHwqdg0mJJwLH-QcA",
    Instagram: "https://www.instagram.com/james347750",
    Github: "https://github.com/takagisanmaaa",
  };

  return (
    <>
      {/* <div className="social-media-layout"> */}
      <div className="social-media">
        <Link to={url_link[logo]}>
          <img src={logoToDisplay} alt={props.logo + " Logo"} />
          <h3>{logo}</h3>
        </Link>
      </div>
      {/* </div> */}
    </>
  );
}

export default Logo;
