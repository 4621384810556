import React from "react";
import Logo from "../components/Logo";
// import { Link } from "react-router-dom";

function SocialMedia() {
  const logo = [
    "Facebook",
    "Discord",
    "Twitter",
    "Telegram",
    "Wechat",
    "Instagram",
    "Github",
  ];
  let lists = logo.map((list) => <Logo logo={list} key={list} />);

  return (
    <>
      <div className="social-media-layout">{lists}</div>
      {/* <h3>
        <Link to="https://www.facebook.com/cryptopan2022">Facebook</Link>
      </h3>
      <h3>
        <Link to="https://discordapp.com/users/james1pan">Discord</Link>
      </h3>
      <h3>
        <Link to="https://twitter.com/james347750">Twitter</Link>
      </h3>
      <h3>
        <Link to="https://t.me/cryptopan2022">Telegram</Link>
      </h3>
      <h3>
        <Link to="https://u.wechat.com/kMqhK3MHwqdg0mJJwLH-QcA">Wechat</Link>
      </h3>
      <Link to="https://github.com/takagisanmaaa">
        <h3>Github</h3>
      </Link> */}
    </>
  );
}

export default SocialMedia;
