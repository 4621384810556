import React from "react";
import { Link, Outlet } from "react-router-dom";
import "../css/navbar.css";

function NavBar() {
  return (
    <>
      <div className="nav-background">
        <Link to="/">
          <h2>Home</h2>
        </Link>
        <Link to="/LearningRoadMap">
          <h2>Learning-RoadMap</h2>
        </Link>
        <Link to="/seniorProject">
          <h2>SeniorProject</h2>
        </Link>
        {/* <Link to="/porfolio">
          <h2>porfolio</h2>
        </Link> */}
        <Link to="/project">
          <h2>project</h2>
        </Link>
        <Link to="/social">
          <h2>SocialMedia</h2>
        </Link>
      </div>
      <Outlet />
    </>
  );
}

export default NavBar;
