import Navbar from "./components/Navbar";
// import "./App.css";

function App() {
  return (
    <div className="App">
      <Navbar />

      <header className="App-header">
        {/* <p>
          主畫面
        </p> */}
      </header>
    </div>
  );
}

export default App;
